/**
 * Dependencies
 */
import {polaris} from "./config";
import {submitForm, websiteNav, constant, loadPage} from "./core";


/**
 * Global variables
 */
let   localStorage = window.localStorage;
const partials     = document.querySelector(".partials");


/**
 * Public Page APIs
 */
export const pageAPIs = () => {
    // Acive toggle for menu plugin
    websiteNav('.menu-plugin', 'menu--active');

    // Load more content
    loadMore();

    // Blog gallery splide
    blogGallerySplide();
};


/**
 * @desc Loads more content by JavaScript after clicking a button (BLOG)
 * 
 * @return string
 */
const loadMore = () => {
    const loadMoreBtns = document.querySelectorAll('.load-more');
    if (loadMoreBtns.length > 0) {
        loadMoreBtns.forEach(btn => {
            btn.onclick = () => {
                // Query requirements
                const id     = btn.dataset.id;
                const qty    = btn.dataset.qty;
                const offset = btn.dataset.offset;
                const url    = btn.dataset.url;
                const api    = partials.dataset.api;

                // Query selector
                const btnText = btn.innerHTML;

                // Prepare the button
                btn.setAttribute("disabled", true);
                btn.innerHTML = constant("Requesting...");

                // Fetch more content
                fetch(api, {
                    method: 'put',
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify({load_more: true, id: id, offset: offset, url: url})
                })
                .then(response => response.json())
                .then(result => {
                    // Everything is fine
                    if (result.status === "success") {
                        // Find container
                        const moreContainer = document.querySelector(`.load-more-container[data-id="${id}"]`);

                        // Append temporary content
                        polaris.append('div', moreContainer, "", [], `temp-${id}`);

                        // Add real content
                        moreContainer.querySelector(`#temp-${id}`).outerHTML = result.content;

                        // Update button offset
                        if (result.remain) btn.dataset.offset = Number(offset) + Number(qty);

                        // SPA reload
                        const app    = partials.dataset.app;
                        const source = partials.dataset.source;

                        loadPage(app, source, api, null, false);
                    }

                    // An error occoured
                    else {
                        polaris.alert(constant('Something went wrong!'), "fadeInTop", "fadeOutBottom", "warning", alert, true, 750, 0);
                    }

                    // Content remained
                    if (result.remain) {
                        // Reset the button
                        btn.removeAttribute("disabled");
                        btn.innerHTML = btnText;
                    }
                    // Content not remained
                    else {
                        btn.style.display = "none";
                    }
                });

            };
        });
    }
}


/**
 * @desc Loads blog gallery splide
 * 
 * @return string
 */
const blogGallerySplide = () => {
    /**
     * Gallery Splide
     */
    if (document.querySelector('#blog-gallery-main') && document.querySelector('#blog-gallery-tmb')) {
        const splideMain = new Splide('#blog-gallery-main', {
            "type": "fade",
            "drag": false,
            "pagination": false,
            "arrows": false
        });

        const splideTmb = new Splide('#blog-gallery-tmb', {
            "skin": "auto",
            "fixedWidth": 140,
            "fixedHeight": 80,
            "gap" : 2,
            "rewind": true,
            "autoplay": true,
            "interval": 6000,
            "speed": 1000,
            "drag": false,
            "arrows": false,
            "pagination": false,
            "isNavigation": true,
            "direction": partials.dataset.dir
        });

        splideMain.sync(splideTmb);
        splideMain.mount();
        splideTmb.mount();
    }
}
