/**
 * Dependencies
 */
import {polaris} from "./config";
import {submitForm, fetchData, capital, constant} from "./core";


/**
 * Global variables
 */
let localStorage = window.localStorage;


/**
 * Settings App APIs
 */
export const settingsAPIs = () => {
    // General settings
    generalSettings();
    
    // Language settings
    langSettings();

    // Custom CSS Settings
    cssSettings();

    // Custom JS Settings
    jsSettings();

	// Edit app
    editApp();

	// Edit user
    editUser();

    // User register settings
    userRegister();
    
    // Analytics settings
    analyticsSettings();
    
    // Advanced settings
    advancedSettings();

    // Blog settings
    blogSettings();

    // Color settings
    colorSettings();

	// Manage links (quick access)
	manageLinks();
};


/**
 * General settings
 */
const generalSettings = () => {
    const form = document.querySelector('#general-settings');
    if (form) {
        form.onsubmit = () => {
            // Handle form submition
            submitForm(form)
            .then((result) => {
                // Check page refresh
                if (result.data.refresh) {
                    // Disable the submit
                    form.querySelector('.submit').setAttribute('disabled', true);
                    
                    // Update the dynamic link
                    const link = document.querySelector("#dynamic-link");
                    link.setAttribute('href', result.data.next);

                    // Trigger the click event
                    setTimeout(() => {
                        link.click();
                    }, 1000);
                }
            });

            // Prevent default behavior
            return false;
        };
    }
};


/**
 * Language settings
 */
const langSettings = () => {
    const form = document.querySelector('#language-settings');
    if (form) {
        form.onsubmit = () => {
            // Handle form submition
            submitForm(form);

            // Prevent default behavior
            return false;
        };
    }
}


/**
 * Custom CSS settings
 */
const cssSettings = () => {
	const form = document.querySelector('#css-settings');
	if (form) {
		// Form submition
		form.onsubmit = () => {
            // Handle form submition
            submitForm(form);

            // Prevent default behavior
            return false;
		};
	}
};


/**
 * Custom JS settings
 */
const jsSettings = () => {
	const form = document.querySelector('#js-settings');
	if (form) {
		// Form submition
		form.onsubmit = () => {
            // Handle form submition
            submitForm(form);

            // Prevent default behavior
            return false;
		};
	}
};


/**
 * Edit app
 */
const editApp = () => {
    const manageApp = document.querySelector("#manage-app");
	if (manageApp) {
		const selectors = manageApp.querySelectorAll(".edit-app");
        const form      = document.querySelector('#dynamic-form');

        if (selectors.length > 0) {
			selectors.forEach((selector) => {
				selector.onclick = () => {
                    // Fetch requirements
					const id   = selector.dataset.id;
                    const name = selector.dataset.name;
    
                    // Produce modal content
                    const method = form.dataset.method;
                    const action = form.dataset.action;
                    const title = `${constant("Edit")} ${capital(name)}`;

                    // Temporary form body
                    const body = `
                    <form action="${action}" method="${method}" enctype="multipart/form-data">
                        ${form.innerHTML}
                        <div>${constant("Loading...")}</div>
                    </form>
                    `;

					// Create modal
                    polaris.modal(title, body, "", "xs", "zoomIn", "zoomOut", "", true, true, 500);

                    // Final form
                    const finalForm = document.querySelector('.modal').querySelector('form');

                    // Fetch form body API
                    fetch(action, {
                        method: method,
                        headers: new Headers({
                            'Content-Type': 'application/json'
                        }),
                        body: JSON.stringify({
                            appsModal: id
                        })
                    })
                    .then(response => response.json())
                    .then(result => {
                        // Check result
                        if (result.status === 'success') {
                            // Update final form body
                            finalForm.querySelector('div').outerHTML = result.content;

                            // Form submition
                            finalForm.onsubmit = () => {
                                // Handle form submition
                                submitForm(finalForm)
                                .then((result) => {
                                    // On success
                                    if (result.status) {
                                        // Update app table
                                        let tr = manageApp.querySelector(`tr[data-id='${id}']`);

                                        // Update SPA
                                        tr.querySelector('td.spa-text').innerHTML = result.data.spaText;
                                        selector.dataset.spa = result.data.spa;
                                    }
                                });
                    
                                // Prevent default behavior
                                return false;
                            };

                        }
                    });

                    // Prevent default behavior
                    return false;
				}
			});
		}
	}
};


/**
 * Edit user
 */
const editUser = () => {
    const manageUsers = document.querySelectorAll(".manage-users");
    manageUsers.forEach(manageUser => {
        if (manageUser) {
            const selectors = manageUser.querySelectorAll(".edit-user");
            const form      = document.querySelector('#dynamic-form');
    
            if (selectors.length > 0) {
                selectors.forEach((selector) => {
                    selector.onclick = () => {
                        // Fetch requirements
                        const id       = selector.dataset.id;
                        const username = selector.dataset.username;
    
                        // Produce modal content
                        const method = form.dataset.method;
                        const action = form.dataset.action;
                        const title  = `${constant("Edit")} '${username}'`;

                        // Temporary body
                        const body = `
                        <form action="${action}" method="${method}" enctype="multipart/form-data">
                            ${form.innerHTML}
                            <div>${constant("Loading...")}</div>
                        </form>
                        `;

                        // Create modal
                        polaris.modal(title, body, "", "md", "zoomIn", "zoomOut", "", true, true, 500);
    
                        // Final form
                        const finalForm = document.querySelector('.modal').querySelector('form');

                        // Fetch form body API
                        fetch(action, {
                            method: method,
                            headers: new Headers({
                                'Content-Type': 'application/json'
                            }),
                            body: JSON.stringify({
                                usersModal: id
                            })
                        })
                        .then(response => response.json())
                        .then(result => {
                            // Check result
                            if (result.status === 'success') {
                                // Update final form body
                                finalForm.querySelector('div').outerHTML = result.content;

                                /**
                                 * Handle final form
                                 */
                                const controlParent = finalForm.querySelector("#field-control-parent");
                                const accessParent  = finalForm.querySelector("#field-access-parent");
                                const appsParent    = finalForm.querySelector("#field-apps-parent");
                                const adminAccess   = finalForm.querySelector("#field-admin-access-parent");
                                const adminIndex    = finalForm.querySelector("#field-admin-index-parent");

                                // User type change
                                finalForm.querySelector('#field-type').onchange = function() {
                                    // Admin
                                    if (this.value == '1') {
                                        controlParent.classList.remove("display-none");
                                        accessParent.classList.remove("display-none");
                                        appsParent.classList.remove("display-none");
                                        adminAccess.classList.remove("display-none");
                                        adminIndex.classList.remove("display-none");
                                    }
                                    // Other
                                    else {
                                        controlParent.classList.add("display-none");
                                        accessParent.classList.add("display-none");
                                        appsParent.classList.add("display-none");
                                        adminAccess.classList.add("display-none");
                                        adminIndex.classList.add("display-none");
                                    }
                                };
            
                                // User status change
                                finalForm.querySelector('#field-status').onchange = function() {
                                    if (this.value == 0) {
                                        // Enable Apps
                                        appsParent.querySelectorAll('.switch').forEach((elem) => {
                                            // Moderate & Limited access
                                            if (finalForm.querySelector('#field-access').value == 0 || finalForm.querySelector('#field-access').value == 1) {
                                                if (['admin'].includes(elem.querySelector('input').value)) {
                                                    elem.classList.remove('switch-disabled');
                                                    elem.querySelector('input').removeAttribute('disabled');
                                                }
                                            }
                                            // Full access
                                            else {
                                                if (!['page', 'user', 'profile'].includes(elem.querySelector('input').value)) {
                                                    elem.classList.remove('switch-disabled');
                                                    elem.querySelector('input').removeAttribute('disabled');
                                                }
                                            }
                                        });
            
                                        // Enable Control
                                        controlParent.querySelectorAll('.switch').forEach((elem) => {
                                            elem.classList.remove('switch-disabled');
                                            elem.querySelector('input').removeAttribute('disabled');
                                        });
            
                                        // Enable Access
                                        accessParent.querySelector("select").removeAttribute('disabled');
                                        adminAccess.querySelector("input").removeAttribute('disabled');
                                        adminIndex.querySelector("input").removeAttribute('disabled');
                                    }
                                    else {
                                        // Disable Apps
                                        appsParent.querySelectorAll('.switch').forEach((elem) => {
                                            if (!['page', 'user', 'profile'].includes(elem.querySelector('input').value)) {
                                                elem.classList.add('switch-disabled');
                                                elem.querySelector('input').setAttribute('disabled', true);
                                            }
                                        });
            
                                        // Disable Control
                                        controlParent.querySelectorAll('.switch').forEach((elem) => {
                                            elem.classList.add('switch-disabled');
                                            elem.querySelector('input').setAttribute('disabled', true);
                                        });
            
                                        // Disable Access
                                        accessParent.querySelector("select").setAttribute('disabled', true);
                                        adminAccess.querySelector("input").setAttribute('disabled', true);
                                        adminIndex.querySelector("input").setAttribute('disabled', true);
                                    }
                                };
            
                                // User access change
                                finalForm.querySelector('#field-access').onchange = function() {
                                    // Moderate & Limited access
                                    if (this.value == 0 || this.value == 1) {
                                        // Disable Apps
                                        appsParent.querySelectorAll('.switch').forEach((elem) => {
                                            if (!['page', 'user', 'profile','admin'].includes(elem.querySelector('input').value)) {
                                                elem.classList.add('switch-disabled');
                                                elem.querySelector('input').setAttribute('disabled', true);
                                            }
                                        });
                                    }
                                    // Full access
                                    else {
                                        // Enable Apps
                                        appsParent.querySelectorAll('.switch').forEach((elem) => {
                                            if (!['page', 'user', 'profile','admin'].includes(elem.querySelector('input').value)) {
                                                elem.classList.remove('switch-disabled');
                                                elem.querySelector('input').removeAttribute('disabled');
                                            }
                                        });
                                    }
                                };
            
                                // Form submition
                                finalForm.onsubmit = () => {
                                    // Handle form submition
                                    submitForm(finalForm)
                                    .then((result) => {
                                        // On success
                                        if (result.status) {
                                            // Check page refresh
                                            if (result.data.refresh) {
                                                // TODO: Close the modal
                                                
                                                // Update the dynamic link
                                                const link = document.querySelector("#dynamic-link");
                                                link.setAttribute('href', result.data.next);

                                                // Trigger the click event
                                                setTimeout(() => {
                                                    link.click();
                                                }, 1000);
                                            } 
                                            else {
                                                // Update app table
                                                let tr = manageUser.querySelector(`tr[data-id='${id}']`);
                
                                                // Update status
                                                tr.querySelector('td.status-text').innerHTML = result.data.statusText;
                                                selector.dataset.status = result.data.statusCode;
                                            }
                                        }
                                    });
                        
                                    // Prevent default behavior
                                    return false;
                                };
                            }
                        });
    
                        // Prevent default behavior
                        return false;
                    }
                });
            }
        }
    });
};


/**
 * User register settings
 */
const userRegister = () => {
    const form = document.querySelector('#user-register');
    if (form) {
        form.onsubmit = () => {
            // Handle form submition
            submitForm(form);

            // Prevent default behavior
            return false;
        };
    }
};


/**
 * Analytics settings
 */
const analyticsSettings = () => {
    const form = document.querySelector('#analytics-settings');
    if (form) {
        form.onsubmit = () => {
            // Handle form submition
            submitForm(form);

            // Prevent default behavior
            return false;
        };
    }
};


/**
 * Advanced settings
 */
const advancedSettings = () => {
    const form = document.querySelector('#advanced-settings');
    if (form) {
        form.onsubmit = () => {
            // Handle form submition
            submitForm(form);

            // Prevent default behavior
            return false;
        };
    }
};


/**
 * Blog settings
 */
export const blogSettings = () => {
    const form = document.querySelector('#blog-settings');
    if (form) {
        form.onsubmit = () => {
            // Handle form submition
            submitForm(form);

            // Prevent default behavior
            return false;
        };
    }
};


/**
 * Color settings
 */
export const colorSettings = () => {
    const form = document.querySelector('#color-settings');
    const doc  = document.querySelector('body');
    if (form) {
        // Color tune
        const tune = document.querySelector('#gray-tune');
        tune.onchange = function() {
            const current_tune  = doc.dataset.tune;

            doc.classList.remove(`doc-${current_tune}`);
            doc.classList.add(`doc-${this.value}`);

            doc.dataset.tune = this.value;
        };

        // App color
        const colors = document.querySelectorAll('.pallete--items');
        colors.forEach(color => {
            color.onclick = function() {
                const current_color = doc.dataset.color;
                const current_item  = document.querySelector('.pallete--active');
    
                if (color !== current_item) {
                    doc.classList.remove(`doc-${current_color}`);
                    doc.classList.add(`doc-${this.dataset.color}`);
                    doc.dataset.color = this.dataset.color;

                    form.querySelector('#field-color').value = this.dataset.color;
        
                    current_item.classList.remove('pallete--active');
                    color.classList.add('pallete--active');
                }
            };
        });

        // Form submit
        form.onsubmit = () => {
            // Handle form submition
            submitForm(form);

            // Prevent default behavior
            return false;
        };
    }
};


/**
* Manage links (quick access)
*/
const manageLinks = () => {
   const parent = document.querySelector("#manage-links");

   // Check container
   if (parent) {
       const output	     = parent.querySelector("#links-output");
       let   dynamicForm = document.querySelector('#dynamic-form');
       const action      = dynamicForm.dataset.action;

       // Add link
       if (parent.querySelector("#create-link-form")) {
           const form = parent.querySelector("#create-link-form");
           form.onsubmit = () => {
               // Handle form submition
               submitForm(form)
               .then((result) => {
                   // On success
                   if (result.status) {
                       // Update the output
                       output.innerHTML = result.data.output;

                       // Reset the form
                       form.reset();

                       // Initialize polaris
                       polaris.init();

                       // Method recursion
                       manageLinks();
                   }
               });
   
               // Prevent default behavior
               return false;
           };
       }

       // Add sublink
       if (parent.querySelectorAll(".add-sublink").length > 0) {
           const selectors = parent.querySelectorAll(".add-sublink");

           selectors.forEach((selector) => {
               selector.onclick = () => {
                   const id 	= selector.getAttribute("data-id");
                   dynamicForm = document.querySelector('#dynamic-form');

                   // Produce modal content
                   const title = constant('Add Sublink');
                   const body  = `
                   <form action="${action}" method="post">
                       ${dynamicForm.innerHTML}
                   </form>
                   `;

                   // Create modal
                   polaris.modal(title, body, "", "sm", "zoomIn", "zoomOut", "", true, true, 500);

                   // Final form
                   const form = document.querySelector('.modal').querySelector('form');

                   // Update form
                   form.querySelector('input[name=form-type]').value = 'create-sublink';
                   form.querySelector('input[name=id]').value = id;
                   form.querySelector('.form-body').innerHTML = `
                       <div class="row">
                           <div class="col col-12 t-col-6">
                               <div class="row gt-sm">
                                   <div class="col w-100 lt-w-150px">
                                       <label for="field-title">*${constant('Title')}</label>
                                   </div>
                                   <div class="col w-rest">
                                       <input type="text" name="title" id="field-title" class="field field-sm field-focus" required>
                                   </div>
                               </div>
                           </div>
                           <div class="col w-100 t-col-6">
                               <div class="row gt-sm">
                                   <div class="col w-100 lt-w-150px">
                                       <label for="field-link">*${constant('Link URL')}:</label>
                                   </div>
                                   <div class="col w-rest">
                                       <input type="text" name="link" class="field field-sm field-focus" id="field-link" value="#" required>
                                   </div>
                               </div>
                           </div>
                           <div class="col col-12 t-col-6">
                               <div class="row gt-sm">
                                   <div class="col w-100 lt-w-150px">
                                       <label for="field-target">${constant('Link Target')}:</label>
                                   </div>
                                   <div class="col w-rest">
                                       <select name="target" class="field field-sm field-focus" id="field-target">
                                           <option value="_self" selected="">Self</option>
                                           <option value="_blank">Blank</option>
                                       </select>
                                   </div>
                               </div>
                           </div>
                           <div class="col col-12 t-col-6 mb-md">
                               <div class="row gt-sm">
                                   <div class="col w-100 lt-w-150px">
                                       <label for="field-spa">${constant('SPA Link')}:</label>
                                   </div>
                                   <div class="col w-rest">
                                       <div class="switch switch-slim switch-sm">
                                           <input type="checkbox" name="spa" id="field-spa">
                                           <div class="switch--slider"></div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <button type="submit" class="btn btn-sm btn-app btn-border btn-focus submit mb-min">${constant('Submit')}</button>
                   `;

                   // Form submition
                   form.onsubmit = () => {
                       // Handle form submition
                       submitForm(form)
                       .then(result => {
                           // On success
                           if (result.status) {
                               // Update the output
                               output.innerHTML = result.data.output;
       
                               // Reset the form
                               form.reset();

                               // Initialize polaris
                               polaris.init();

                               // Method recursion
                               manageLinks();
                           }
                       });

                       // Prevent default behavior
                       return false;
                   };
               }
           });
       }

       // Edit link | sublink
       if (parent.querySelectorAll(".edit-link").length > 0) {
           const selectors = parent.querySelectorAll(".edit-link");

           selectors.forEach((selector) => {
               selector.onclick = () => {
                   const id 	= selector.getAttribute("data-id");
                   const sub 	= selector.getAttribute("data-sub");
                   dynamicForm = document.querySelector('#dynamic-form');

                   // Produce modal content
                   const title = Number(sub) ? constant('Edit Sublink') : constant('Edit Link');
                   const body  = `
                   <form action="${action}" method="put">
                       ${dynamicForm.innerHTML}
                   </form>
                   `;

                   // Create modal
                   polaris.modal(title, body, "", "sm", "zoomIn", "zoomOut", "", true, true, 500);

                   // Final form
                   const form = document.querySelector('.modal').querySelector('form');

                   // Update form
                   form.querySelector('input[name=form-type]').value = 'edit-link';
                   form.querySelector('input[name=id]').value = id;
                   form.querySelector('.form-body').innerHTML = constant("Loading...");

                   // Fetch the form body
                   fetchData(null, {linkSource: id}, action, 'put', null)
                   .then(result => {
                       if (result.status) {
                           // Update the form body
                           form.querySelector('.form-body').innerHTML = result.data.source;

                           // Form submition
                           form.onsubmit = () => {
                               // Handle form submition
                               submitForm(form)
                               .then(result => {
                                   // On success
                                   if (result.status) {
                                       // Update the output
                                       output.innerHTML = result.data.output;
               
                                       // Initialize polaris
                                       polaris.init();
       
                                       // Method recursion
                                       manageLinks();
                                   }
                               });
       
                               // Prevent default behavior
                               return false;
                           };
                       }
                   });
               }
           });
       }

       // Delete link
       if (parent.querySelectorAll('.delete-link').length > 0) {
           const selectors = parent.querySelectorAll('.delete-link');

           selectors.forEach((selector) => {
               // Delete link
               selector.onclick = function() {
                   const id 	= this.dataset.id;
                   const popup = parent.querySelector(`#delete-popup-${id}`);
       
                   // Start the delete process
                   fetchData(selector, {delete_link: id}, action, 'delete')
                   .then((result) => {
                       // On success
                       if (result.status) {
                           // Close popup
                           popup.classList.remove("popup-open");

                           // Update the output
                           output.innerHTML = result.data.output;

                           // Initialize polaris
                           polaris.init();

                           // Method recursion
                           manageLinks();
                       }
                   });
               };
           });
       }

       // Sorting link
       const containers = document.querySelectorAll('.drag-list');
       if (containers.length) {
           containers.forEach(container => {
               container.onmousedown = () => {
                   polaris.drag(container).then(() => {
                       // Fetch required data
                       const form 	 = document.querySelector('#dynamic-form');
                       const action = form.dataset.action;
                       const method = form.dataset.method;
   
                       const draggables = Array.from(container.children);
                       let   data 		 = {};
   
                       draggables.forEach(draggable => {
                           data[draggable.dataset.id] = Number(draggable.dataset.order);
                       });
   
                       // Perform the sorting process
                       fetchData(null, {sortLink: data}, action, method, null);
                   });
               };
           });
       }

   }	
};
